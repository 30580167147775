import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import getMandatoryEnv from "sites-common/utils/getMandatoryEnv";

const useCommonData = () => {
  const { kmcFormConfig } = getMandatoryEnv(["kmcFormConfig"]);
  const [loading, setLoading] = useState(true);
  const [homeBannerData, setHomeBannerData] = useState();
  const [bannerData, setBannerData] = useState([]);

  const homeBannerGetData = useCallback(() => {
    axios.get(`${kmcFormConfig.formUrl}/api/kanha/getbanner`).then((res) => {
      setHomeBannerData(res?.data);
      setLoading(false);
    });
  }, [kmcFormConfig.formUrl]);

  const compareStartEndDateWithCurrentDate = (
    displayStartDate,
    displayEndDate
  ) => {
    const startD = displayStartDate.split("/");
    const endD = displayEndDate.split("/");
    const startDate = new Date(`${startD[1]}/${startD[0]}/${startD[2]}`);
    const endDate = new Date(`${endD[1]}/${endD[0]}/${endD[2]}`);
    return startDate < new Date() && new Date() < endDate;
  };

  useEffect(() => {
    homeBannerGetData();
  }, [homeBannerGetData]);

  useEffect(() => {
    if (homeBannerData?.count > 0) {
      const test = homeBannerData?.data?.filter((item) => {
        const { Visible_from_date, Visible_to_date } = item;
        if (
          compareStartEndDateWithCurrentDate(Visible_from_date, Visible_to_date)
        ) {
          return item;
        }
        return null;
      });
      setBannerData(test);
    }
  }, [homeBannerData]);

  return {
    loading,
    homeBannerData,
    bannerData,
  };
};

export default useCommonData;
