import React from "react";

import instaFeed from "../../../assets/data/insta";

const KanhaFeedSection = () => {
  return (
    <div className="kanha-feed-wrapper pt-3">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
              <a
                href="https://www.instagram.com/kanhashantivanam/"
                target="_blank"
                rel="noopener noreferrer"
              >
                @kanhashantivanam
              </a>
            </div>
          </div>
          {instaFeed.map((item) => {
            return (
              <div className="col-md-3" key={item.id}>
                <div className="img-wrapper">{item.img}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default KanhaFeedSection;
